import {Link} from "gatsby";
import React from "react";
import "./header.css";
import Menu from "../menu/menu";
import DarkModeToggle from "../dark-mode-toggle/dark-mode-toggle";
import VisitorCounter from "../visitor-count/visitor-count";

const Header = () => {
  return (
    <header className="header">
      <div className="header__top-panel flex items-center">
        <h1 className="header__logo">
          <Link to={`/`}>NΞILBLAZΞ</Link>
        </h1>
        <div className="ml-auto lg:flex items-center">
          <DarkModeToggle />
          <div className="hidden lg:block">
            <div className="flex items-center -ml-2 mr-2 no-underline">
              <span className="dividerx mr-4 ml-4"></span>
              <span className="pulsey h-4 w-4 mr-2"></span>
              <a
                href="https://stats.uptimerobot.com/YXDp6h3YRm"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm mr-2 ml-1 text-green-500 hover:text-emerald-400 hover:no-underline"
              >
                <b className="no-underline">Operational</b>
              </a>
            </div>
          </div>
          <VisitorCounter />
        </div>
      </div>
      <Menu />
    </header>
  );
};
export default Header;
