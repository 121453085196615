import React, { useState, useEffect } from 'react';

const VisitorCounter = () => {
  const [visitorCount, setVisitorCount] = useState(1);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel('visitor-count');
    const eventSource = new EventSource('https://neilblaze-sse.onrender.com/');

    const updateVisitorCount = (data) => {
      const count = Math.max(1, data.count !== undefined ? data.count : data[0]);
      setVisitorCount(count);
      broadcastChannel.postMessage(count);
      localStorage.setItem('visitorCount', count);
    };

    eventSource.addEventListener('connected', (event) => {
      const data = JSON.parse(event.data);
      updateVisitorCount(data);
    });

    eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      updateVisitorCount(data);
    });

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      setVisitorCount(prevCount => Math.max(1, prevCount));
    };

    const handleBeforeUnload = () => {
      const currentCount = parseInt(localStorage.getItem('visitorCount'), 10) || 1;
      const newCount = Math.max(1, currentCount - 1);
      localStorage.setItem('visitorCount', newCount);
      broadcastChannel.postMessage(newCount);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    broadcastChannel.onmessage = (event) => {
      setVisitorCount(Math.max(1, parseInt(event.data, 10)));
    };

    const storedCount = localStorage.getItem('visitorCount');
    if (storedCount) {
      setVisitorCount(Math.max(1, parseInt(storedCount, 10)));
    }

    return () => {
      eventSource.close();
      broadcastChannel.close();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="visitor-counter hidden lg:block flex items-center pointer-events-none">
      <img 
        src="https://res.cloudinary.com/dmlwye965/image/upload/v1727280638/stxzcnq1at7e0wedtisw.gif" 
        alt="Visitor Icon" 
        className="inline-block mr-1"
        style={{ width: '18px', height: '18px', verticalAlign: 'middle', marginTop: '0px', marginRight: '5px' }}
      />
      <span className="visitor-count" style={{ fontSize: '15px', fontWeight: 'bold', verticalAlign: 'middle', marginRight: '10px' }}>
        {visitorCount}
      </span>
    </div>
  );
};

export default VisitorCounter;