import React from "react";

import "./contact-list.css";

const ContactList = () => (
  <ul className="contacts-list">
    <li>
      <span className="contacts-list__type">E-mail</span>
      <a href="mailto:putubanerjee23@gmail.com" className="contacts-list__link link">
        neilblaze007@gmail.com
      </a>
    </li>
    <li>
      <span className="contacts-list__type">Phone</span>
      <a href="tel:+918420342836" className="contacts-list__link link">
        (+91)8420-34-2836
      </a>
    </li>
  </ul>
);

export default ContactList;
